.App {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.fade-in-loader {
	animation: fadeInLoaderAnimation 0.5s linear;
}

.fade-out-loader {
	animation: fadeOutLoaderAnimation 0.5s linear;
}

@keyframes fadeInLoaderAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.85;
	}
}

@keyframes fadeOutLoaderAnimation {
	0% {
		opacity: 0.85;
	}
	100% {
		opacity: 0;
	}
}
